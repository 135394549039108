body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* main color #fcd7d7 */

input {
  box-shadow: -50px 30px 0px #333;
}

/* HTML: <div class="loader"></div> */
.loader-button {
  width: 30px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 4px solid #0000;
  border-right-color: #ff8000f7;
  position: relative;
  animation: l24 1s infinite linear;
}

.loader-button:before,
.loader-button:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}

.loader-button:after {
  animation-duration: 4s;
}

@keyframes l24 {
  100% {
    transform: rotate(1turn)
  }
}