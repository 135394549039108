h1 {
  display: flex;
  flex-direction: row;
  font-family: "Poppins";
  font-weight: lighter;
  color: #464441;
  text-shadow: 30px;
  box-shadow: #464441 20px;
}
h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  border-color: #d3d3d3;
  margin: auto;
}
h1:before {
  margin-right: 10px;
}
h1:after {
  margin-left: 10px;
}
